class LabelFormatDimmedStrategy {
  constructor(modelFormatter) {
    this.modelFormatter = modelFormatter;
  }

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label. * @return {string}
   */
  getRecordCustomLabelForDisplay = function(record, options = {}) {
    return this.modelFormatter.getRecordCustomLabelForDisplayAlternate(record, options);
  };

  getRecordNameFromLabel = function(label, typeCode) {
    let regexRule = new RegExp(`(.*) - ${typeCode}-\\d+`);

    const match = regexRule.exec(label);
    if (!match) {
      throw new Error(`Cannot extract name from label ${label} for record type ${typeCode}`);
    }

    return match[1];
  };
}

module.exports = {
  LabelFormatDimmedStrategy,
};